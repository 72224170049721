import React from "react"

import Layout from "../components/layout"

export default () => (
  <Layout>
    <h1>404 Oops :(</h1>
    <p>This page does not exist</p>
  </Layout>
)
